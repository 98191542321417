import { Router } from '@reach/router'
import PhotosChildren from '../../components/PhotosChildren'

const FotkyDeti = () => {
  return (
    <Router>
      <PhotosChildren
        {...({ path: '/fotky-deti/:page' } as PagePropsPagination)}
      />
      <PhotosChildren {...({ path: '/fotky-deti/' } as PagePropsPagination)} />
    </Router>
  )
}
export default FotkyDeti
